<template>
    <div class="form-password">
        <div class="mb-3">
            <ui-input
                id="password"
                name="password"
                label="Password"
                placeholder="********"
                autocomplete="new-password"
                v-model:value="formData.password"
                :type="show['password'] ? 'text' : 'password'"
                :field="v$.formData.password"
            >
                <template #append>
                    <button type="button" class="form-password__toggle-button btn" @click="toggleShowPassword('password')">
                        <span class="fas" :class="{ 'fa-eye': show['password'], 'fa-eye-slash': !show['password'] }"></span>
                    </button>
                </template>
            </ui-input>
        </div>
        <div v-if="showPasswordConfirmation" class="mb-3">
            <ui-input
                id="password_confirmation"
                name="password_confirmation"
                label="Confirm password"
                placeholder="********"
                autocomplete="new-password"
                v-model:value="formData.password_confirmation"
                :type="show['password_confirmation'] ? 'text' : 'password'"
                :field="v$.formData.password_confirmation"
            >
                <template #append>
                    <button type="button" class="form-password__toggle-button btn" @click="toggleShowPassword('password_confirmation')">
                        <span class="fas" :class="{ 'fa-eye': show['password_confirmation'], 'fa-eye-slash': !show['password_confirmation'] }"></span>
                    </button>
                </template>
            </ui-input>
        </div>
        <ui-button
            variant="link"
            @click="generatePassword"
        >
            <span class="fas fa-sync"></span> Generate password
        </ui-button>
        <hr />
        <h6>Password Requirements:</h6>
        <ul class="form-password__list">
            <li>
                <span v-if="isValidated('containsUppercase')" class="far fa-check-circle text-success"></span>
                <span v-else class="fas fa-long-arrow-alt-right"></span>
                Must contain one upper case letter.
            </li>
            <li>
                <span v-if="isValidated('containsLowercase')" class="far fa-check-circle text-success"></span>
                <span v-else class="fas fa-long-arrow-alt-right"></span>
                Must contain one lower case letter.
            </li>
            <li>
                <span v-if="isValidated('containsNumber')" class="far fa-check-circle text-success"></span>
                <span v-else class="fas fa-long-arrow-alt-right"></span>
                Must contain one number.
            </li>
            <li>
                <span v-if="isValidated('containsSpecialCharacter')" class="far fa-check-circle text-success"></span>
                <span v-else class="fas fa-long-arrow-alt-right"></span>
                Must contain one special character.
            </li>
            <li>
                <span v-if="isValidated('minLength')" class="far fa-check-circle text-success"></span>
                <span v-else class="fas fa-long-arrow-alt-right"></span>
                Length must be a minimum of {{ min }} characters.
            </li>
        </ul>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { helpers, minLength, required, sameAs } from '@vuelidate/validators'

import UiButton from '@/components/ui/buttons/Button'
import UiInput from '@/components/ui/Input';

const containsLowercase = (value) => /[a-z]/.test(value);
const containsNumber = (value) => /\d/.test(value);
const containsUppercase = (value) => /[A-Z]/.test(value);
const containsSpecialCharacter = (value) => /([[\]@$!%*¡¿?&#=|.(){}:;<>,/~_+-])/.test(value);

export default {
    name: 'FormPassword',
    components: { UiButton, UiInput },
    props: {
        showPasswordConfirmation: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        const formData = { password: '' };

        if (this.showPasswordConfirmation) {
            formData.password_confirmation = '';
        }

        return {
            formData,
            min: 12,
            show: {
                password: false,
                password_confirmation: false,
            },
        };
    },
    validations() {
        const validations = {
            password: {
                required: helpers.withMessage('Password is required.', required),
                minLength: helpers.withMessage(`Length must be a minimum of ${this.min} characters.`, minLength(this.min)),
                containsLowercase: helpers.withMessage('Must contain one lower case letter.', containsLowercase),
                containsNumber: helpers.withMessage('Must contain one number.', containsNumber),
                containsUppercase: helpers.withMessage('Must contain one upper case letter.', containsUppercase),
                containsSpecialCharacter: helpers.withMessage('Must contain one special character.', containsSpecialCharacter),
                $autoDirty: true,
            },
        };

        if (this.showPasswordConfirmation) {
            validations.password_confirmation ={
                required: helpers.withMessage('Must confirm the password.', required),
                sameAsPassword: helpers.withMessage('Passwords must match.', sameAs(this.formData.password)),
                $autoDirty: true,
            };
        }

        return {
            formData: validations,
        };
    },
    methods: {
        toggleShowPassword(inputName) {
            this.show[inputName] = !this.show[inputName];
        },
        reset() {
            if (this.showPasswordConfirmation) {
                this.formData.password_confirmation = '';
            }

            this.formData.password = '';
            this.show.password = false;
            this.show.password_confirmation = false;
            this.v$.formData.$reset();
        },
        isValidated(validator) {
            if (this.formData.password === '') {
                return false
            }
            
            if (this.v$.formData.password.$errors.length === 0 && this.formData.password !== '') {
                return true;
            }

            return this.v$.formData.password.$errors.length > 0 &&
                !this.v$.formData.password.$errors.find(error => error.$validator === validator);
        },
        cryptoRandom() {
            const array = new Uint8Array(1);
            const random_value = crypto.getRandomValues(array)[0];
    
            return random_value / Math.pow(2, 8);
        },
        generatePassword() {
            let characters_array = [
                'abcdefghijklmnopqrstuvwxyz',
                'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
                '0123456789',
                '![]{}()%&*$#^~@|',
            ];
            let password = '';
            let position = 0;

            characters_array = characters_array.sort(() => (0.5 - this.cryptoRandom()));

            for(let i = 0; i < this.min; i++) {
                const current_characters = characters_array[position];

                password += current_characters.charAt(Math.floor(this.cryptoRandom() * current_characters.length));
                position++;

                if (position >= characters_array.length) {
                    position = 0;
                    characters_array = characters_array.sort(() => (0.5 - this.cryptoRandom()));
                }
            }

            this.formData.password = password;

            if (this.showPasswordConfirmation) {
                this.formData.password_confirmation = password;
            }

            this.show.password = true;

            // Copy to clipboard
            navigator.clipboard
                .writeText(password)
                .then(() => {
                    this.$toast.success('Password copied to clipboard.');
                })
                .catch(() => {
                    this.$toast.error('There was an error copying the password to the clipboard, you need to copy it manually')
                });
        },
        async validate() {
           return await this.v$.$validate();
        }
    },
};
</script>

<style lang="scss">
.form-password {
    &__list{ 
        list-style: none;
        padding-left: 0;
    }

    &__input-group {
        border: 1px solid #c9d2e3;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus-within {
            color: #3c4e71;
            background-color: #ffffff;
            border-color: #8fb5ff;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(31 107 255 / 25%);
        }

        &--is-invalid {
            border-color: #ff3b30;
            
            &:focus-within {
                border-color: #ff3b30;
                box-shadow: 0 0 0 0.25rem rgb(255 59 48 / 25%);
            }
        }

        &--is-valid {
            border-color: #0cb884;
            
            &:focus-within {
                border-color: #0cb884;
                box-shadow: 0 0 0 0.25rem rgb(12 184 132 / 25%);
            }
        }
    }

    &__input {
        border: none;
        box-shadow: none;
        
        &:focus {
            box-shadow: none;
        }
    }

    &__toggle-button {
        border: none;
        color: $white;
        height: 100%;
        padding: 0;
        width: 100%;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            color: $white;
        }
    }
}
</style>
